//==============================
// Global
//==============================

FastClick.attach(document.body);

/*
$('.element').waypoint(function(direction) {
  var thisElement = $(this.element);
  //stuff
  this.destroy();
}, {
  offset: '50%'
});
*/

// $('.thing-with-videos').fitVids();
